import React from 'react';
import { Link } from 'gatsby';

import Logo from "../images/logo.svg"

import { Container, Navbar, Nav, NavDropdown, Dropdown, Row, Col} from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';


const NewNav = () => {

    return(
        <Container className="pt-4">
            <Navbar expand="xl" collapseOnSelect={true}>
               <Link to="/">
                   <img src={Logo} alt="Real Christianity Unplugged" width="150"/>
               </Link>
               <Navbar.Toggle aria-controls="navbarResponsive" />
               <Navbar.Collapse id="navbarResponsive">
                   <Nav className=" ml-auto">
                       <ul className="navbar-nav">
                           <li>
                           <NavDropdown
                                className="pr-2 pb-2 align-text-top"
                                title="ReaCh Library"
                                id="basic-nav-dropdown"
                             >
                                <Container className="eventsNav pt-0 mt-0">
                                    <Row>
                                    <Col xs="12" md="6" className="text-left">
                                        <Dropdown.Header>
                                        <i class="fas fa-bible pr-1" color="black"></i>
                                       
                                        {"  "}
                                        <Link href="/biblestudy">
                                            <a>
                                            Bible Studies
                                            </a>
                                        </Link>
                                        
                                        </Dropdown.Header>
                                        <Dropdown.Item>
                                        <Link to="/biblestudy/theword">
                                            <a className="nav-link" role="button">
                                           The Word
                                            </a>
                                        </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                        <Link to="/biblestudy/prophecy">
                                            <a className="nav-link" role="button">
                                            Prophecy
                                            </a>
                                        </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                        <Link to="/biblestudy/channel">
                                            <a className="nav-link" role="button">
                                            ReaCh Channel
                                            </a>
                                        </Link>
                                        </Dropdown.Item>

                                        <Dropdown.Divider />
                                        <Dropdown.Header>
                                        <i class="fas fa-heartbeat pr-1" color="black"></i>
                                       
                                        {"  "}
                                        <Link href="/lifestyles">
                                            <a>
                                            LifeStyles
                                            </a>
                                        </Link>
                                       
                                        </Dropdown.Header>
                                        <Dropdown.Item>
                                        <Link to="/lifestyles/country">
                                            <a className="nav-link" role="button">
                                            Country Living
                                            </a>
                                        </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                        <Link to="/lifestyles/faithwalks">
                                            <a className="nav-link" role="button">
                                           Faith Walks
                                            </a>
                                        </Link>
                                        </Dropdown.Item>
                                      
                                        <Dropdown.Divider className="d-md-none" />
                                    </Col>

                                    <Col xs="12" md="6" className="text-left">
                                        <Dropdown.Header>
                                        <i class="fas fa-home pr-1" color="black"></i>
                                      
                                        {"  "}
                                        <Link to="/family">
                                            <a>
                                            Family
                                            </a>
                                        </Link>
                                      
                                        </Dropdown.Header>
                                        <Dropdown.Item>
                                        <Link to="/family/mw">
                                            <a className="nav-link" role="button">
                                            Men & Women
                                            </a>
                                        </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                        <Link to="/family/youth">
                                            <a className="nav-link" role="button">
                                            Youth
                                            </a>
                                        </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                        <Link to="/family/kids">
                                            <a className="nav-link" role="button">
                                            Kids
                                            </a>
                                        </Link>
                                        </Dropdown.Item>
                                        <Dropdown.Divider />
                                        <Dropdown.Header>
                                        <i class="fas fa-microphone-alt pr-1" color="black"></i>
                                        {"  "}
                                       Inspirational
                                        </Dropdown.Header>
                                        <Dropdown.Item>
                                        <Link to="/devotions">
                                            <a className="nav-link" role="button">
                                            Morning Devotions
                                            </a>
                                        </Link>
                                        </Dropdown.Item>
                                    </Col>
                                    </Row>
                                </Container>
                             </NavDropdown>
                           </li>
                           <li>
                               <Link to="/about" className="nav-link" activeClassName="active">About</Link>
                           </li>
                           <li>
                               <Link to="/diet" className="nav-link" activeClassName="active">Diet</Link>
                           </li>
                           <li>
                               <Link to="/health_fitness" className="nav-link" activeClassName="active">Health & Fitness</Link>
                           </li>
                           <li>
                               <Link to="/projects" className="nav-link" activeClassName="active">Projects</Link>
                           </li>
                           <li>
                               <Link to="/worldtoday" className="nav-link" activeClassName="active">World Today</Link>
                           </li>
                           <li>
                               <Link to="/contact" className="nav-link" activeClassName="active">Contact</Link>
                           </li>
                       </ul>
                   </Nav>
               </Navbar.Collapse>
            </Navbar>
        </Container>

    )
}

export default NewNav